<!-- 登录页面 -->
<template>
  <div ref="threejs">

    <div class="justify-center items-center flex h-screen w-screen bg-gray-200">
      <v-card
        class="mx-auto rounded-xl-bk opacity-50 hover:opacity-100 transition duration-500 ease-in-out"
        max-width="360px"
        width="100%"
        :elevation="5"
        style="z-index: 1;"
      >
        <v-img
          class="align-end text-white"
          height="100"
          src="https://cdn.vuetifyjs.com/images/cards/docks.jpg"
          cover
        >
          <v-card-title class="text-center text-h5">管理后台</v-card-title>
        </v-img>

        <v-card-subtitle class="pt-4 text-center">
          
        </v-card-subtitle>

        <v-card-text>
          <v-text-field
            v-model="username"
            name="username"
            label="用户名"
            type="text"
            variant="solo"
            rounded="xl"
          ></v-text-field>

          <v-text-field
            v-model="password"
            name="password"
            label="密码"
            :type="isShowPassword ? 'text' : 'password'"
            variant="solo"
            rounded="xl"
            :append-inner-icon="isShowPassword ?`mdi-eye` : `mdi-eye-off`"
            @click:append-inner="showPassword"
          ></v-text-field>
        </v-card-text>

        <v-card-actions class="justify-center">
          <v-btn color="pink" class="rounded-xl-bk opacity-70 hover:opacity-100" :elevation="5" @click="onLogin" width="100%">
            登录
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>
  </div>
</template>
<script setup>
  import * as THREE from 'three';
  import { ref } from 'vue'
  import login  from '~/api/login';

  const website = useWebsiteStore()
  const isShowPassword = ref(false)
  const showPassword = () => {
    isShowPassword.value = !isShowPassword.value
  }
  const router = useRouter()

  const onLogin = () => {
    console.log('login')
    login.login({ username: username.value, password: password.value })
      .then(res => {
        if(res?.data?.token){
          website.setToken(res.data.token)
          website.setTitle('天准品牌体验馆管理后台')
        }
        if(res?.data?.data){
          website.setUser(res.data.data)
        }
        router.push('/')
      })
  }
  const username = ref('')
  const password = ref('')
  const threejs = ref(null)
  // nuxt 判断 是否 浏览器环境
  if (process.client) {

    const width = window.innerWidth, height = window.innerHeight;

    const camera = new THREE.PerspectiveCamera( 70, width / height, 0.01, 10 );
    camera.position.z = 1;

    window.scene = new THREE.Scene();

    watch(() => website.dark, (newVal) => {
      if( process.client ){
        // 切换 tailwindcss 模式
        scene.background = website.dark ? new THREE.Color( 0x1e293b ) : new THREE.Color( 0xf1f5f9 );
      }
    })

    // 创建很多星星 随机颜色和位置，大小
    const geometry = new THREE.SphereGeometry( 0.001, 10, 10 );
    const material = new THREE.MeshBasicMaterial( { color: 0xffffff } );
    const mesh = new THREE.Mesh( geometry, material );
    scene.add( mesh );
      // 随机颜色
      function getRandomHexColorWithPrefix() {
        return '0x' + Math.floor(Math.random() * 16777215).toString(16);
      }

    for ( let i = 0; i < 150; i ++ ) {
      const geometry = new THREE.SphereGeometry( 0.001, 10, 10 );

      const material = new THREE.MeshBasicMaterial( { color: parseInt(getRandomHexColorWithPrefix()) } );
      var s = new THREE.Mesh( geometry, material );
      s.position.set( Math.random() - 0.5, Math.random() - 0.5, Math.random() - 0.5 ).normalize().multiplyScalar( Math.random() );
      s.scale.multiplyScalar( Math.random() );
      scene.add( s );
    }

    const renderer = new THREE.WebGLRenderer( { antialias: true } );
    renderer.setSize( width, height );
    renderer.setAnimationLoop( animation );

    onMounted(() => {
      scene.background = website.dark ? new THREE.Color( 0x1e293b ) : new THREE.Color( 0xf1f5f9 );
      renderer.domElement.style.position = 'absolute';
      renderer.domElement.style.top = 0
      threejs.value.appendChild( renderer.domElement );
      website.setTitle('登录')
    });

    // resize
    window.addEventListener( 'resize', () => {
        camera.aspect = window.innerWidth / window.innerHeight;
        camera.updateProjectionMatrix();
        renderer.setSize( window.innerWidth, window.innerHeight );
    } );

    // animation

    function animation( time ) {

        camera.position.z = Math.cos( time / 10000 ) * 0.25;
        camera.position.x = Math.sin( time / 10000 ) * 0.25;
        camera.position.y = Math.sin( time / 10000 ) * 0.25;
        camera.lookAt( 0, 0, 0 );
        renderer.render( scene, camera );
    }

  }

</script>
